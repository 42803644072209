form {
  label {
    display: block;
    margin-top: 20px;
    margin-bottom: 4px;
    &:nth-child(1) {
      margin-top: 10px;
    }
    &.required:after {
        content: " *";
        color: $bgcolor;
    }
  }

  input[type=text],input[type=email],textarea {
    color: #334;
    padding: 6px;
    border-radius: 6px;
    border: solid 1px #ddd;
    &::-webkit-input-placeholder {
      color: #ddd;
      font-size: 18px;
    }
  }

  input[type=text],input[type=email] {
    font-size: 24px;
    width: 300px;
  }

  textarea {
    width: 400px;
    max-width: 100%;
    height: 100px;
    font-size: 20px;
  }

  button[type=submit] {
    color: #fff;
    background-color: $bgcolor;
    border-radius: 6px;
    font-size: 18px;
    border: 0;
    padding: 12px;
    margin-top: 20px;
    display: block;
    width: 300px;
  }
}