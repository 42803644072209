$bgcolor: #e83855;
$lightTextColor: lighten($bgcolor, 20%);
$darkTextColor: darken($bgcolor, 20%);

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "myriad-pro", sans-serif;
  background-color: #fff;
  color: #334;
  font-size: 20px;
}

a {
  &,&:visited {
    color: $bgcolor;
  }

  &:hover {
    color: $bgcolor;
  }
}

.home {
  margin: 0;
  padding: 0;
  min-height: 350px;
}

.hero {
  background-color: $bgcolor;
  margin: 0 auto;
  padding: 40px;
  padding-top: 180px;
  padding-bottom: 80px;
  text-align: center;

  .landing-nav {
      position: absolute;
      top: 10px;
      right: 30px;
      a {
          font-size: 14px;
          color: #fff;
      }
      @media screen and (max-width: 800px) {
          display: none;
      }
  }
}

.feature-phone {
    position: absolute;
    right: -83px;
    transform: rotate(-25deg);
    width: 200px;
    top: 300px;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: 800px) {
        transform: translateX(100px) rotate(-15deg);
    }
    &:hover {
        transform: translateX(-60px) rotate(-5deg);
    }
}

.device-icons {
    display: flex;
    align-items: center;
}

.main {
    margin: 60px auto;
    max-width: 800px;
}

section.intro {
    h1 {
        font-weight: bold;
    }
}

section.skills {
    margin: 20px 0;
    margin-bottom: 60px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
            h3 {
                font-weight: 500;
                border-bottom: solid 1px #eee;
            }
            flex: 1;
            margin: 10px;
            &:nth-child(1) {
                margin-left: 0;
            }
            // background-color: #fafafa;
            border-radius: 8px;
            padding: 10px 0px;
        }
    }

    @media screen and (max-width: 800px) {
        margin: 20px 20px;
    }
}

.pipe {
  display: inline-block;
  padding: 0 20px;
  color: $darkTextColor;
}

.subtitle {
  color: $darkTextColor;
  margin: 48px 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.4em;
}

@media screen and (max-width: 800px) {
    .hero {
        padding-top: 40px;
        padding-bottom: 20px;
        img.logo {
            max-width: 70%;
        }
    }
    .subtitle {
        margin-top: 10px;
    }
    section.intro {
        margin-left: 20px;
        margin-right: 20px;
    }
    section.skills {
        ul {
            flex-direction: column;
        }
    }
}


.landing-footer {
  background-color: $bgcolor;

  ul {
    display: flex;
    width: 188px;
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    li {
      padding: 0;
      display: block;
      margin: 20px 18px;

      a {
        path { fill: #fff }
        &:hover {
          path { fill: lighten($bgcolor, 10%); }
        }
      }
    }
  }
}


@media screen and (max-height: 350px) {
  .landing-footer {
    bottom: inherit;
    position: relative;
  }
}

/**
 * Site header
 */
.site-header {
    padding: 20px 30px;

    // Positioning context for the mobile navigation icon
    position: relative;
    background-color: $bgcolor;
    a.site-title {
        img.logo {
            width: 200px;
        }
    }
}

// .site-title {
//     font-size: 26px;
//     line-height: 56px;
//     letter-spacing: -1px;
//     margin-bottom: 0;
//     float: left;

//     &,
//     &:visited {
//         color: #fff;
//     }
// }

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid darken($bgcolor, 5%);
    padding: $spacing-unit 0;

    a {
       color: lighten($bgcolor, 15%);
    }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
    li {
      display: block;
      margin: 5px;
      float: left;
    }
}

.footer-col-wrapper {
    font-size: 15px;
    color: #fff;
    margin-left: calc(-#{$spacing-unit} / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit $spacing-unit;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
